import React from 'react'
import laserImg from '../assets/ltfree.jpg'

export default function Laser() {
  return (
    <section className='bg-white'>

    <div id='laser' className="gap-16 items-center py-16 px-6 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:px-6">
      <div className="text-slate-600 sm:text-lg">
          <h2 className="mb-6 text-3xl tracking-tight font-bold text-slate-900 lg:text-4xl">CNC 5-axis 3D Laser Cutting Service</h2>
          <p className='mb-6 text-lg leading-8 text-slate-600'>Our factory is equipped with the latest technology from <a href='https://www.blmgroup.com/3d-cutting-cells/lt-free' className='text-sky-600'> BLM Group </a> – the industry leader in 3D laser cutting.</p>
          <p className='mb-6 text-lg leading-8 text-slate-600'>Whether you have bent, hydroformed, extruded, cold drawn, shaped tubes or any other three-dimensional part. We can help you cut it with high accuracy and speed.</p>
          <p className="mb-6 text-lg leading-8 text-slate-600">With a cutting accuracy of ±0.05mm, we guarantee the utmost precision and uncompromising quality for all your 3D CNC laser cutting needs.</p>
      </div>
      <div className="flex justify-center">
          <img className="rounded-lg w-auto" src={laserImg} alt="BLM LT-Free"/>
      </div>
  </div>
    
  </section>
  )
}
