import { ArrowDownCircleIcon, ArrowsPointingInIcon, LockClosedIcon } from '@heroicons/react/24/outline'

const features = [
  {
    name: 'Precision Engineering',
    description:
      'Achieve tight tolerances (±0.1mm), ensuring your product\'s exact specifications are met every time.',
    icon: ArrowsPointingInIcon,
  },
  {
    name: 'Enhanced Strength',
    description:
      'Harness the power of high forming degrees and accelerated work hardening rates, resulting in a tube with superior strength.',
    icon: LockClosedIcon,
  },
  {
    name: 'Lightweight Excellence',
    description:
      'Optimize weight without sacrificing performance by crafting improved shapes with enhanced properties.',
    icon: ArrowDownCircleIcon,
  },
]

export default function Benefit() {
  return (
    <div className="bg-white py-16 lg:pt-24 lg:pb-8">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="mt-2 text-3xl font-bold tracking-tight text-slate-900 sm:text-4xl">
            Elevate Your Design with Hydroforming
          </h2>
          <p className="mt-6 text-lg leading-8 text-slate-600">
            Hydroforming allows you to design lower weight tubes. Wall thicknesses can be reduced to a minimum. 
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-full">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-3 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="text-lg font-semibold leading-7 text-gray-900">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-slate-600">
                    <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-lg leading-7 text-slate-600">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
