import Detail from "./components/Detail.jsx";
import Hero from "./components/Hero.jsx";
import Navbar from "./components/Navbar.jsx";
import Products from "./components/Products.jsx";
import References from "./components/References.jsx";
import Bottom from "./components/Bottom.jsx";
import Laser from "./components/Laser.jsx";
import About from "./components/About.jsx";
import Whyus from "./components/Whyus.jsx";
import Machine from "./components/Machine.jsx";
import Offer from "./components/Offer.jsx";
import Benefit from "./components/Benefit.jsx";
import { Tolgee, DevTools, TolgeeProvider, FormatSimple } from "@tolgee/react";

const tolgee = Tolgee()
  .use(DevTools())
  .use(FormatSimple())
  .init({
    language: "en",

    // for development
    apiUrl: process.env.REACT_APP_TOLGEE_API_URL,
    apiKey: process.env.REACT_APP_TOLGEE_API_KEY,

    staticData: {
      en: () => import("./i18n/en.json"),
    },
  });

function App() {
  return (
    <>
      <TolgeeProvider
        tolgee={tolgee}
        fallback="Loading..." // loading fallback
      >
        <Navbar />
        <Hero />
        <Offer />
        <Products />
        <Benefit />
        <Machine />
        <Whyus />
        <Detail />
        <Laser />
        <About />
        <References />
        <Bottom />
      </TolgeeProvider>
    </>
  );
}

export default App;
