import React from 'react'
import aboutImg from '../assets/JiouJiou-Jerry-Yu-Lucas-Fuelling.JPG'

const timeline = [
    {
      name: '成立公司',
      description:
        '盂曾年創立久玖實業股份有限公司，開始為自行車行業生產鋼管。',
      date: '1989',
      dateTime: '1989-01',
    },
    {
      name: '第一台液壓成型機',
      description:
        '投資第一台液壓成型機。 由於電動自行車需求不斷增長，久玖開始更多地轉向鋁管。',
      date: '2015',
      dateTime: '2015-01',
    },
    {
      name: '第二台液壓成型機',
      description:
        '投資第二台液壓成型機，產能2000噸。 為摩托車行業實現更大管材的液壓成型。',
      date: '2019',
      dateTime: '2019-01',
    },
    {
      name: '3D激光切割',
      description:
        '投資高科技激光切割。 亞洲第一台 BLM LT-Free 激光切割機。',
      date: '2023',
      dateTime: '2023-04',
    },
  ]

export default function About() {
  return (
    <div>
        {/* Hero section */}
        <div id='about' className="relative isolate -z-10 overflow-hidden bg-gradient-to-b from-blue-100/20 pt-8">
          <div
            className="absolute inset-y-0 right-1/2 -z-10 -mr-96 w-[200%] origin-top-right skew-x-[-30deg] bg-white shadow-xl shadow-sky-600/10 ring-1 ring-sky-50 sm:-mr-80 lg:-mr-96"
            aria-hidden="true"
          />
          <div className="mx-auto max-w-7xl px-6 py-12 sm:py-24 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
              <h1 className="max-w-2xl text-4xl font-bold tracking-tight text-slate-900 sm:text-4xl lg:col-span-2 xl:col-auto">
              公司簡介
              </h1>
              <div className="mt-6 max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">

                <p class="text-slate-600 sm:text-lg">久玖實業股份有限公司由盂曾年於1989年創立，以對接和液壓成型管材而聞名。</p>
                <p class="text-slate-600 sm:text-lg">我們生產自行車、電動自行車、踏板車、摩托車和懸架系統的內胎。</p>

                <h3 class="text-xl font-semibold pb-4 pt-8">致力於質量和創新</h3>
                <p class="text-slate-600 sm:text-lg">質量不僅僅是一個詞；而是一個詞。 這是我們的簽名。 久玖對卓越的不懈追求銘刻在我們生產的每一根管材中。 我們採用最先進的製造工藝、嚴格的質量控制以及對細節的堅定不移的關注，確保每支帶有久玖名稱的管材都符合最高標準。</p>
                
                <h3 class="text-xl font-semibold pb-4 pt-8">一次塑造行業一管</h3>
                <p class="text-slate-600 sm:text-lg">發現久玖的差異並體驗三十多年來定義我們的工程奇蹟。 我們將共同塑造行業的未來，並重新定義可能性——一次一根管子。</p>
      
              </div>
              <img
                src= {aboutImg}
                alt=""
                className="mt-10 aspect-[6/5] w-full max-w-lg rounded-2xl object-cover sm:mt-16 lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2 xl:mt-0"
              />
            </div>
          </div>
          <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32" />
        </div>

        {/* Timeline section */}
        <div className="mx-auto -mt-8 max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-8 overflow-hidden lg:mx-0 lg:max-w-none lg:grid-cols-4">
            {timeline.map((item) => (
              <div key={item.name}>
                <time
                  dateTime={item.dateTime}
                  className="flex items-center text-sm font-semibold leading-6 text-sky-600"
                >
                  <svg viewBox="0 0 4 4" className="mr-4 h-1 w-1 flex-none" aria-hidden="true">
                    <circle cx={2} cy={2} r={2} fill="currentColor" />
                  </svg>
                  {item.date}
                  <div
                    className="absolute -ml-2 h-px w-screen -translate-x-full bg-slate-900/10 sm:-ml-4 lg:static lg:-mr-6 lg:ml-8 lg:w-auto lg:flex-auto lg:translate-x-0"
                    aria-hidden="true"
                  />
                </time>
                <p className="mt-6 text-lg font-semibold leading-8 tracking-tight text-slate-900">{item.name}</p>
                <p className="mt-1 text-lg leading-7 text-slate-600">{item.description}</p>
              </div>
            ))}
          </div>
        </div>
    </div>
  )
}
