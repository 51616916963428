import Benefit from "./components_cn/Benefit_cn.jsx";
import Detail from "./components_cn/Detail_cn.jsx";
import Hero from "./components_cn/Hero_cn.jsx";
import Navbar from "./components_cn/Navbar_cn.jsx"
import Products from "./components_cn/Products_cn.jsx";
import References from "./components_cn/References_cn.jsx";
import Bottom from "./components_cn/Bottom_cn.jsx";
import Laser from "./components_cn/Laser_cn.jsx";
import About from "./components_cn/About_cn.jsx";
import Whyus from "./components_cn/Whyus_cn.jsx";


function AppCN() {
  return (
    <>
      <Navbar/>
      <Hero/>
      <Benefit/>
      <Products/>
      <Whyus/>
      <Detail/>
      <Laser/>
      <About/>
      <References/>
      <Bottom/>
    </>
  );
}

export default AppCN;
