import React from 'react'
import leftImg from '../assets/pt625.png'

export default function Products() {
  return (
    <section id='hydroforming' className='bg-white'>

      <div className="gap-16 items-center py-8 px-6 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:px-6">
        <div className="text-slate-600 sm:text-lg">
            <h2 className="mb-6 text-3xl tracking-tight font-bold text-slate-900 lg:text-4xl">Hydroforming Experts - Aluminum tubes for mobility applications</h2>
            <p className="mb-6 text-lg leading-8 text-slate-600">When it comes to crafting top-tier hydroformed tubes, our team boasts unmatched expertise across every facet of the production process. From precision cold drawing to cutting-edge 3D CNC laser technology, we've strategically brought every step in-house, ensuring not only the highest quality but also fast lead times for our valued clients.</p>            
            </div>
        <div className="flex justify-center">
            <img className="rounded-lg w-auto lg:h-[600px]" src={leftImg} alt="hydroformed tube"/>
        </div>
    </div>

    </section>
  )
}
