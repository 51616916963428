import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/solid'
import { useState } from 'react'
import {Link} from 'react-scroll'


export default function Navbar() {
    const [nav, setNav] = useState(false)
    function handleClick(){setNav(!nav)}
    
    return (
      <>
      
    <div className='w-full h-[61px] bg-white drop-shadow-lg'>
      <div className='px-2 flex justify-between items-center w-full h-full'>
          <h1 className='font-bold text-2xl  ml-4 md:text-3xl italic'>JiouJiou</h1>
          <ul className='hidden md:flex space-x-6'>
            <li className='cursor-pointer'><Link activeClass="active" to="hydroforming" spy={true} smooth={true}>Hydroforming</Link></li>
            <li className='cursor-pointer'><Link activeClass="active" to="about" spy={true} smooth={true}>About</Link></li>
            <li className='cursor-pointer'><Link activeClass="active" to="laser" spy={true} smooth={true}>3D Laser</Link></li>
            <li className='cursor-pointer'><Link activeClass="active" to="contact" spy={true} smooth={true}>Contact</Link></li>
          </ul>
          
          <div className='hidden md:flex items-center pr-4'>
          <a className='' href="/tw">中文</a>
          </div>

          <div className='md:hidden' onClick={handleClick}>
          {!nav ? <Bars3Icon className='w-7'/> : <XMarkIcon className='w-7'/>}
          </div>
      </div>
 
        <ul className={!nav ? 'hidden' : 'fixed w-full bg-white z-50 right-0 px-8'}>
        <li className='cursor-pointer mt-4'><Link onClick={handleClick} activeClass="active" to="hydroforming" spy={true} smooth={true}>Hydroforming</Link></li>
        <li className='cursor-pointer'><Link onClick={handleClick} activeClass="active" to="about" spy={true} smooth={true}>About</Link></li>
        <li className='cursor-pointer'><Link onClick={handleClick} activeClass="active" to="laser" spy={true} smooth={true}>3D Laser</Link></li>
        <li className='cursor-pointer'><Link onClick={handleClick} activeClass="active" to="contact" spy={true} smooth={true}>Contact</Link></li>
        <li className='cursor-pointer mb-4'><a onClick={handleClick} href='/tw'>中文</a></li>
        </ul>

    </div>
</>
  );
}
