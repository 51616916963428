import React from 'react'
import { MapPinIcon, PhoneIcon, EnvelopeIcon } from '@heroicons/react/24/outline'

const navigation = {

    social: [
      {
        name: 'LinkedIn',
        href: 'https://www.linkedin.com/in/lucas-fuelling/',
        icon: (props) => (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              fill="currentColor"
              viewBox="0 0 24 24">
              <path
                d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
            </svg>
        ),
      },
      {
        name: 'Whatsapp',
        href: 'https://wa.me/+886933952299',
        icon: (props) => (
            <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            fill="currentColor"
            viewBox="0 0 24 24">
            <path
                d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z" />
            </svg>
        ),
      },

    ],
  }
  
  export default function Bottom() {
    return (
      <footer id='contact' className="bg-white">
        <div className="mx-auto max-w-7xl overflow-hidden px-6 py-16 sm:py-24 lg:px-8">
          <h2 className='text-4xl font-bold tracking-tight mb-2 text-center'>聯繫我們</h2>
          <div class="flex justify-center">
  <div class="max-w-sm bg-white rounded-lg py-8 px-6 shadow-lg">
    <h2 class="font-semibold">久玖實業股份有限公司</h2>
    <div class="flex  mt-4">
      <MapPinIcon className='w-6 h-6 mr-2'/>
      <div>
        <p>臺中市大甲區日南里東二街19號</p>
      </div>
      
    </div>

    <div class="flex mt-3">
      <EnvelopeIcon className='w-6 h-6 mr-2'/>
      <p>lucas@jioujiou.de</p>
    </div>
    <div class="flex mt-3">
      <PhoneIcon className='w-6 h-6 mr-2'/>
      <p>+886-4-2681-5715</p>
    </div>
  </div>
</div>



          <div className="mt-10 flex justify-center space-x-10">
            {navigation.social.map((item) => (
              <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500">
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </a>
            ))}
          </div>
          <p className="mt-10 text-center text-xs leading-5 text-gray-500">
            &copy; 2023 久玖實業股份有限公司 All rights reserved.
          </p>
        </div>
      </footer>
    )
  }
  