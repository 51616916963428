import React from 'react'
import laserImg from '../assets/ltfree.jpg'

export default function Laser() {
  return (
    <section className='bg-white'>

    <div id='laser' className="gap-16 items-center py-16 px-6 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:px-6">
      <div className="text-slate-600 sm:text-lg">
          <h2 className="mb-6 text-3xl tracking-tight font-bold text-slate-900 lg:text-4xl">3D激光切割</h2>
          <p className='mb-6 text-lg leading-8 text-slate-600'>我們的工廠配備了 3D 激光切割行業領導者 BLM 集團的最新技術。</p>
          <p className='mb-6 text-lg leading-8 text-slate-600'>無論您有彎曲、液壓成型、擠壓、冷拔、異形管還是任何其他三維零件。 我們可以幫助您高精度、高速度地切割它。</p>
          <p className="mb-6 text-lg leading-8 text-slate-600">憑藉 ±0.05mm 的切割精度，我們保證滿足您所有 3D 激光切割需求的最高精度和毫不妥協的質量。</p>
      </div>
      <div className="flex justify-center">
          <img className="rounded-lg w-auto" src={laserImg} alt="BLM LT-Free"/>
      </div>
  </div>
    
  </section>
  )
}
