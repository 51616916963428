import React from 'react'
import aboutImg from '../assets/JiouJiou-Jerry-Yu-Lucas-Fuelling.avif'

const timeline = [
    {
      name: 'Founded company',
      description:
        'Jerry Yu founded JiouJiou and started producing steel tubes for the bicycle industry.',
      date: '1989',
      dateTime: '1989-01',
    },
    {
      name: '1st Hydroforming machine',
      description:
        'Investment into first hydroforming machine. JiouJiou started shifting more into aluminum tubes due to rising demand for e-bikes.',
      date: '2015',
      dateTime: '2015-01',
    },
    {
      name: '2nd Hydroforming machine',
      description:
        'Investment into second hydroforming machine with capacity of 2000T. Enabling hydroforming of bigger tubes for the motorcycle industry.',
      date: '2019',
      dateTime: '2019-01',
    },
    {
      name: '3D Laser cutting',
      description:
        'Investment into high-tech laser cutting from BLM. First BLM LT-Free laser cutting machine in Asia.',
      date: '2023',
      dateTime: '2023-04',
    },
  ]

export default function About() {
  return (
    <div>
        {/* Hero section */}
        <div id='about' className="relative isolate -z-10 overflow-hidden bg-gradient-to-b from-blue-100/20 pt-8">
          <div
            className="absolute inset-y-0 right-1/2 -z-10 -mr-96 w-[200%] origin-top-right skew-x-[-30deg] bg-white shadow-xl shadow-sky-600/10 ring-1 ring-sky-50 sm:-mr-80 lg:-mr-96"
            aria-hidden="true"
          />
          <div className="mx-auto max-w-7xl px-6 py-12 sm:py-24 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
              <h1 className="max-w-2xl text-4xl font-bold tracking-tight text-slate-900 sm:text-4xl lg:col-span-2 xl:col-auto">
                About JiouJiou
              </h1>
              <div className="mt-6 max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">

                <p class="text-slate-600 sm:text-lg">Founded by Jerry Yu in 1989, JiouJiou is renowned for mastering butted and hydroformed tubes.</p>
                <p class="text-slate-600 sm:text-lg">We produce tubes for bicycles, e-bikes, scooters, motorcycles, and suspension systems.</p>

                <h3 class="text-xl font-semibold pb-4 pt-8">Committed to Quality and Innovation</h3>
                <p class="text-slate-600 sm:text-lg">Quality isn't just a word; it's our signature. JiouJiou's relentless pursuit of excellence is etched into every tube we produce. Our state-of-the-art manufacturing processes, rigorous quality control, and unwavering attention to detail ensure that every tube bearing the JiouJiou name meets the highest standards.</p>
                
                <h3 class="text-xl font-semibold pb-4 pt-8">Shaping Industries One Tube at a Time</h3>
                <p class="text-slate-600 sm:text-lg">Discover the difference and experience our hydroforming manufacturing capabilities that have defined us for over three decades. Together, we'll shape the future of industries and redefine what's possible — one tube at a time.</p>
      
              </div>
              <img
                src= {aboutImg}
                alt=""
                className="mt-10 aspect-[6/5] w-full max-w-lg rounded-2xl object-cover sm:mt-16 lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2 xl:mt-0"
              />
            </div>
          </div>
          <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32" />
        </div>

        {/* Timeline section */}
        <div className="mx-auto -mt-8 max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-8 overflow-hidden lg:mx-0 lg:max-w-none lg:grid-cols-4">
            {timeline.map((item) => (
              <div key={item.name}>
                <time
                  dateTime={item.dateTime}
                  className="flex items-center text-sm font-semibold leading-6 text-sky-600"
                >
                  <svg viewBox="0 0 4 4" className="mr-4 h-1 w-1 flex-none" aria-hidden="true">
                    <circle cx={2} cy={2} r={2} fill="currentColor" />
                  </svg>
                  {item.date}
                  <div
                    className="absolute -ml-2 h-px w-screen -translate-x-full bg-slate-900/10 sm:-ml-4 lg:static lg:-mr-6 lg:ml-8 lg:w-auto lg:flex-auto lg:translate-x-0"
                    aria-hidden="true"
                  />
                </time>
                <p className="mt-6 text-lg font-semibold leading-8 tracking-tight text-slate-900">{item.name}</p>
                <p className="mt-1 text-lg leading-7 text-slate-600">{item.description}</p>
              </div>
            ))}
          </div>
        </div>
    </div>
  )
}
