import deburringImg from '../assets/deburring.avif'
import thicknessImg from '../assets/thickness-distribution-hydroforming-sm.avif'
import surfaceImg from '../assets/surace-finish-hydroformed-tube-sm.avif'
import React from 'react'

const posts = [
  {
    id: 1,
    title: 'Deburring',
    href: '#',
    description:
      'Sharp edges not only can harm workers during the handling of the product but also damage cables when they are integrated. We manufacture the tubes in a quality that our customers expect.',
    image: deburringImg,

  },

  {
    id: 2,
    title: 'Thickness distribution',
    href: '#',
    description:
      'Together with our customers, we develop cost-effective solutions that meet and pass required strength and fatigue testing with optimal material use.',
    image: thicknessImg,
  },
  {
    id: 3,
    title: 'Surface finishing',
    href: '#',
    description:
      'Every single tube is visually inspected to ensure a defect-free surface finishing. A thorough inspection process avoids rework before painting or anodizing.',
    image: surfaceImg,
  },
]

export default function Detail() {
  return (
    <div className="bg-white py-8 lg:pt-12 lg:pb-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Details that matter</h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
          Details that matter. A hydroformed tube is at the beginning of a long process along the supply chain. With welding as the next step, we make sure that all tubes are made to your requirements. Especially robot welding require a constant quality (precise outer shape with low tolerances) in order to guarantee smooth welding production in your facility.
          </p>
        </div>
        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {posts.map((post) => (
            <article key={post.id} className="flex flex-col items-start justify-between">
              <div className="relative w-full">
                <img
                  src={post.image}
                  alt=""
                  className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                />
                <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
              </div>
              <div className="max-w-xl">

                <div className="group relative">
                  <h3 className="mt-8 text-lg lg:text-2xl font-semibold leading-6 text-slate-900">
                      {post.title}
                  </h3>
                  <p className="mt-5 line-clamp-3 text-lg leading-7 lg:text-lg lg:leading-8 tracking-tight text-slate-600">{post.description}</p>
                </div>
              </div>
            </article>
          ))}
        </div>
      </div>
    </div>
  )
}
