import React from 'react'
import leftImg from '../assets/pt625.png'

export default function Products() {
  return (
    <section id='hydroforming' className='bg-white'>

      <div className="gap-16 items-center py-8 px-6 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:px-6">
        <div className="text-slate-600 sm:text-lg">
            <h2 className="mb-6 text-3xl tracking-tight font-bold text-slate-900 lg:text-4xl">鋁材液壓成形專家</h2>
            <p className="mb-6 text-lg leading-8 text-slate-600">在製造頂級液壓成型管時，我們的團隊在生產過程的各個方面都擁有無與倫比的專業知識。 從精密冷拔到尖端 3D 激光技術，我們戰略性地將每一步都內部化，不僅確保了最高的質量，而且為我們尊貴的客戶提供了閃電般的交貨時間。</p>            
            </div>
        <div className="flex justify-center">
            <img className="rounded-lg w-auto lg:h-[600px]" src={leftImg} alt="hydroformed tube"/>
        </div>
    </div>

    </section>
  )
}
