import { Cog8ToothIcon, LockClosedIcon, BoltIcon } from '@heroicons/react/24/outline'

const features = [
  {
    name: '全面的專業知識',
    description:
      '我們的專家在與液壓成形相關的所有上游和下游製造工藝方面擁有豐富的知識。',
    icon: Cog8ToothIcon,
  },
  {
    name: '質量保證',
    description:
      '憑藉我們的內部能力，我們保持嚴格的質量控制標準，確保每根液壓成型管滿足或超出您的期望。',
    icon: LockClosedIcon,
  },
  {
    name: '效率和速度',
    description:
      '通過內部管理所有流程，我們最大限度地減少延遲並簡化生產，為您提供更短的交貨時間。',
    icon: BoltIcon,
  },
]

export default function Whyus() {
  return (
    <div className="bg-white py-16 lg:pt-8 lg:pb-24">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="mt-2 text-3xl font-bold tracking-tight text-slate-900 sm:text-4xl">
          為什麼選擇我們的鋁管液壓成型服務？?
          </h2>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-full">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-3 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="text-lg font-semibold leading-7 text-gray-900">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-slate-600">
                    <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-lg leading-7 text-slate-600">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}