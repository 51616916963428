import React from 'react'
import leftImg from '../assets/Hydroforming-machine.jpg'

export default function Machine() {
  return (
    <section id='machine' className='bg-white'>

      <div className="gap-16 items-center pt-4 pb-12 px-6 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:px-6">
        <div className="flex justify-center">
            <img className="rounded-lg w-auto lg:h-[450px]" src={leftImg} alt="Wetori Hydroforming Machine"/>
        </div>
        <div className="text-slate-600 sm:text-lg">
            <h2 className="mb-6 text-3xl tracking-tight font-bold text-slate-900 lg:text-4xl">Tube and Sheet Metal Hydroforming OEM</h2>
            <p className="mb-6 text-lg leading-8 text-slate-600">As a manufacturer specializing in Tube and Sheet Metal Hydroforming OEM solutions, we pride ourselves on our custom <a href='https://www.action-wetori.com/product_view/hydroforming-press-ish.html' className='text-sky-600'>Wetori</a> hydroforming machines. With our equipment we are able to hydroform tubes from both ends and allow for a uniform thickness distribution. Our seal plug cylinders differ from standard machines and are sized bigger in order to have enough pressing force to achieve a high quality tube surface. Both of our machines 1000T and 2000T are capable of sheet hydroforming as well.</p>            
        </div>
    </div>

    </section>
  )
}