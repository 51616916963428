import React from 'react'
import rmLogo from '../assets/rm_logo.svg'
import titleLogo from '../assets/title_logo.png'
import shercoLogo from '../assets/sherco.png'
import kymcoLogo from '../assets/kymco.png'
import qbpLogo from '../assets/qbp_logo.svg'

export default function References() {
    return (
        <div className="bg-white py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:max-w-none">
              <h2 className="text-4xl font-bold tracking-tight text-slate-800">
                Our tubes are used by
              </h2>
              <div className="mx-auto mt-10 grid grid-cols-4 items-center gap-x-8 gap-y-10 sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:grid-cols-5">
                <a href='https://www.r-m.de/'> <img
                  className="col-span-2 max-h-12 w-full object-contain object-left lg:col-span-1"
                  src={rmLogo}
                  alt="riese mueller Logo"
                  width={158}
                  height={48}
                /></a>
                <a href='https://titlemtb.com/'><img
                  className="col-span-2 max-h-12 w-full object-contain object-center lg:col-span-1"
                  src={titleLogo}
                  alt="TitleMTB-Logo"
                  width={158}
                  height={48}
                /></a>
                <a href='https://www.qbp.com/'><img
                  className="col-span-2 max-h-12 w-full object-contain object-left lg:col-span-1"
                  src={qbpLogo}
                  alt="QBP-Logo"
                  width={158}
                  height={48}
                /></a>
                <a href='https://kymco.com.tw'><img
                  className="col-span-2 max-h-12 w-full object-contain object-left lg:col-span-1"
                  src={kymcoLogo}
                  alt="Kymco-Logo"
                  width={158}
                  height={48}
                /></a>
                <a href='https://sherco.com'><img
                  className="col-span-2 max-h-12 w-full object-contain object-left lg:col-span-1"
                  src={shercoLogo}
                  alt="Sherco-Logo"
                  width={158}
                  height={48}
                /></a>
              </div>
            </div>
          </div>
        </div>
      )
}
