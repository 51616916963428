import deburringImg from '../assets/IMG_5185-min.jpg'
import thicknessImg from '../assets/thickness-distribution-hydroforming-sm.jpg'
import surfaceImg from '../assets/surace-finish-hydroformed-tube-sm.jpg'
import React from 'react'

const posts = [
  {
    id: 1,
    title: '去毛刺',
    href: '#',
    description:
      '鋒利的邊緣不僅會在處理產品時傷害工人，而且還會在集成時損壞電纜。 我們以客戶期望的質量生產管材。',
    image: deburringImg,

  },

  {
    id: 2,
    title: '厚度分佈',
    href: '#',
    description:
      '我們與客戶一起開發具有成本效益的解決方案，以最佳的材料使用滿足並通過所需的強度和疲勞測試。',
    image: thicknessImg,
  },
  {
    id: 3,
    title: '表面處理',
    href: '#',
    description:
      '每根管子都經過目視檢查，以確保表面光潔度無缺陷。 徹底的檢查過程可避免噴漆或陽極氧化前的返工。',
    image: surfaceImg,
  },
]

export default function Detail() {
  return (
    <div className="bg-white py-8 lg:pt-12 lg:pb-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">重要的細節</h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
          細節很重要。 液壓成型管正處於供應鏈中漫長流程的開始：焊接、釬焊、噴漆、陽極氧化和組裝。 所有步驟都需要注重細節，以獲得準確的精加工，並保證下一步生產過程的順利進行。
          </p>
        </div>
        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {posts.map((post) => (
            <article key={post.id} className="flex flex-col items-start justify-between">
              <div className="relative w-full">
                <img
                  src={post.image}
                  alt=""
                  className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                />
                <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
              </div>
              <div className="max-w-xl">

                <div className="group relative">
                  <h3 className="mt-8 text-lg lg:text-2xl font-semibold leading-6 text-slate-900">
                      {post.title}
                  </h3>
                  <p className="mt-5 line-clamp-3 text-lg leading-7 lg:text-lg lg:leading-8 tracking-tight text-slate-600">{post.description}</p>
                </div>
              </div>
            </article>
          ))}
        </div>
      </div>
    </div>
  )
}
