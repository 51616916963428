import { ArrowDownCircleIcon, ArrowsPointingInIcon, LockClosedIcon } from '@heroicons/react/24/outline'

const features = [
  {
    name: '精密工程',
    description:
      '實現嚴格的公差 (±0.1mm)，確保您的產品每次都符合準確的規格。',
    icon: ArrowsPointingInIcon,
  },
  {
    name: '增強強度',
    description:
      '利用高成形度和加速加工硬化率的力量，生產出具有卓越強度的管材。',
    icon: LockClosedIcon,
  },
  {
    name: '輕量化卓越',
    description:
      '通過改進形狀和增強性能，在不犧牲性能的情況下優化重量。',
    icon: ArrowDownCircleIcon,
  },
]

export default function Benefit() {
  return (
    <div className="bg-white py-16 lg:pt-24 lg:pb-8">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="mt-2 text-3xl font-bold tracking-tight text-slate-900 sm:text-4xl">
            通過液壓成型提升您的設計
          </h2>
          <p className="mt-6 text-lg leading-8 text-slate-600">
          液壓成型允許您設計重量較輕的管材。 壁厚可以減至最小。 
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-full">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-3 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="text-lg font-semibold leading-7 text-gray-900">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-slate-600">
                    <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-lg leading-7 text-slate-600">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
