import React from 'react'
import {
ArrowDownIcon
} from '@heroicons/react/24/solid'

export default function Hero() {
return (
// Flex Container

  <div id="home" className="relative h-[80vh] -z-10 w-full bg-center bg-cover bg-hero bg-scroll mb-4">
    <div className='absolute bg-slate-600 opacity-70 h-full w-full'></div>
      <div className='absolute flex items-center h-full w-full justify-center'>
        <div className='flex flex-col px-6'>
          <h1 className="text-white text-5xl tracking-tight lg:text-7xl">
            <span className='font-bold'>液壓</span> 管件.
          </h1>
          <p className='text-white py-4 text-2xl lg:py-6'>顆粒更細。 更好的力量。 更輕質的管子。</p>
          <div className='flex md:justify-center'>
            <ArrowDownIcon className='mt-5 w-7 stroke-1 stroke-white fill-white animate-bounce'/>
          </div>
        </div>

      </div>



  </div>

)
}