import React from "react";
import { ArrowDownIcon } from "@heroicons/react/24/solid";
import { useTranslate } from "@tolgee/react";

export default function Hero() {
  const { t } = useTranslate();
  return (
    // Flex Container

    <div
      rel="preload"
      id="home"
      className="relative h-[80vh] bg-scroll -z-10 w-full bg-center bg-contain bg-no-repeat md:bg-cover bg-hero mb-4"
    >
      <div className="absolute bg-slate-600 opacity-70 h-full w-full"></div>
      <div className="absolute flex items-center h-full w-full justify-center">
        <div className="flex flex-col px-6">
          <h1 className="text-white text-5xl tracking-tight lg:text-7xl mb-20 md:mb-0">
            <span className="font-bold">{t("hero-title")} </span>
            Taiwan.
          </h1>
          <p className="text-white py-4 text-2xl lg:py-6">
            {t("hero-sub-title")}
          </p>
          <div className="flex md:justify-center">
            <ArrowDownIcon className="mt-5 w-7 stroke-1 stroke-white fill-white animate-bounce" />
          </div>
        </div>
      </div>
    </div>
  );
}
